<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <div class="textCard">
          <!-- <h1>Cam kết bảo mật</h1> -->
          <!-- <div class="title-line"></div> -->
          <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import importedImg1 from "@/page/olelonsa/components/img/4_1.png";

export default {
  data() {
    return {
      altText: "protocolTitle",
      importedImg1: importedImg1,
      text: `¿QUIÉN ES RESPONSABLE DE TUS DATOS PERSONALES?
a nosotros recabará de ti (en adelante el "Titular" o "tú") los siguientes datos personales (en lo sucesivo los "datos" o los "datos personales") en tu calidad de uno de nuestros clientes:
    datos de contacto (número de teléfono celular, correo electrónico, domicilio personal);
    datos de identificación (nombre, fecha de nacimiento, captura de imagen, identificación oficial, género asignado);
    datos biométricos;
    datos de localización y geolocalización (ubicación aproximada, ubicación precisa, huso horario);
    datos laborales y educativos;
    datos de comunicación;
    datos de tu teléfono celular o dispositivo móvil (información del hardware, IMEI, dirección IP, dirección MAC, GPS ADID o identificación de otro dispositivo, operador móvil);
    datos de uso de tu dispositivo (lista de aplicaciones instaladas, datos de cuentas);
    datos de SMS (la marca de tiempo, el número de teléfono y el contenido del SMS);
    lista de contactos, mensajes de texto que envías, registro y frecuencia de llamada de tu teléfono móvil;
    datos patrimoniales y financieros (ingresos o datos o documentos de tu empleo, grado de escolaridad, propósito o uso que se le dará al préstamo);
    datos que proporciones en tu solicitud de préstamo;
    datos transaccionales de las operaciones que realizas con nosotros o a través de nosotros, así como de las operaciones que realizas con transmisores de dinero, instituciones de fondos de pago electrónico, instituciones de financiamiento colectivo, instituciones de crédito y otras instituciones financieras o comercios electrónicos;
    datos de uso de la aplicación de  (detalles de instalación, tráfico, navegación).

¿USAMOS DATOS PERSONALES SENSIBLES?
a nosotros no solicita directamente datos raciales o de origen étnico, de salud, creencias filosóficas o religiosas, afiliación sindical, opiniones políticas, preferencias sexuales (salvo por tu género asignado que se requiere para temas de prevención de lavado de dinero). Sin embargo, recabamos información relacionada con tu lista de contactos, mensajes de texto, registro de llamadas, registro de mensajes de texto e imágenes que incidentalmente puede tener datos personales sensibles.
¿CÓMO OBTENEMOS Y ACTUALIZAMOS TUS DATOS?

Los datos personales serán obtenidos y actualizados a través de:
    Cuando bajas la aplicación de y/o nos solicitas un préstamo;
    Tus otras interacciones con nosotros, incluyendo datos personales que voluntariamente compartes con nuestro departamento de atención al cliente o con nuestros empleados y agentes;
    Tu teléfono celular o dispositivo móvil cuando otorgas acceso y permisos que se describe abajo;
    Buros de crédito o agencias para prevenir el fraude;
    Cookies, web beacons y otros medios remotos de recolección automática de datos;
    Transmisores de dinero instituciones de fondos de pago electrónico, instituciones de financiamiento colectivo, instituciones de crédito y otras instituciones financieras y comercios electrónicos, con tu consentimiento, cuando sea necesario; y/o
    Terceros y otras fuentes públicas, con tu consentimiento, cuando sea necesario.

¿PARA QUÉ USAMOS TUS DATOS?

Las finalidades primarias (en lo sucesivo, las "Finalidades Primarias") que dan origen y son necesarias para el mantenimiento y cumplimiento de la relación jurídica entre y tú son las siguientes:
    Verificar tu identidad, prevenir fraudes y cumplir con nuestras obligaciones de "conoce a tu cliente" bajo las leyes aplicables de prevención de lavado de dinero;
    Crear tu perfil crediticio y asignarte una calificación crediticia para determinar si eres elegible para usar los productos y servicios;
    Llevar a cabo la identificación y el conocimiento mediante recolección de datos personales, así como la integración de tu expediente que se mantendrá en la infraestructura tecnológica y sistemas de información de en otros archivos;
    Incorporar los datos personales en instrumentos jurídicos, dar cumplimiento a los contratos que celebres con, incluyendo, pero no limitado a ciertos contratos de usuario de nuestra aplicación y plataformas y los contratos de préstamo o crédito entre el Titular y administrar y manejar dichos contratos;
    Originar, gestionar, dar servicio, mantener y exigir las deudas del Titular;
    Brindarte servicios y gestionar tu cuenta con darte soporte como cliente;
    Enviarte información de transacciones o notificaciones de transacciones, recordatorios de pago y anuncios a través de servicios de telefonía automáticos o mensajes pre-grabados, mensajes de texto o email;
    Ceder o transmitir a un tercero, mediante cualquier medio, los derechos y/u obligaciones derivadas de los contratos antes señalados;
    Utilizar los datos personales, en cualquier tipo de acto o diligencia de cobranza judicial o extrajudicial;
    Cumplir con todas las leyes, reglamentos y disposiciones de carácter general aplicables;
    Investigar cambios en tu perfil transaccional;
    Prevención de fraudes;
    Obtener datos personales para mejorar los modelos crediticios , sus modelos de datos y otros servicios;
    Analizar el comportamiento del cliente y mantener la calidad de nuestros servicios.
Las finalidades secundarias (en lo sucesivo, las "Finalidades Secundarias") que no son necesarias para el mantenimiento y cumplimiento de la relación jurídica entre tú, son las siguientes:
    Realización de encuestas para mejorar nuestro servicio;
    Uso de imágenes y testimonios que nos proporciones voluntariamente para fines publicitarios; y
    La mercadotecnia, publicidad y/o promoción de los productos y/o servicios que sean ofrecidos por al Titular, por cualquier medio material y/o electrónico. Con tu consentimiento al presente Aviso de Privacidad otorgas tu consentimiento expreso para que podamos contactarte con la finalidad de ofrecerte servicios financieros.
Tu consentimiento es necesario para las finalidades secundarias. Si no estás de acuerdo con el uso de tus datos de contacto para dichas finalidades secundarias, envíanos un correo. Esperaremos cinco días hábiles antes de usar tus datos personales para finalidades secundarias.
¿A QUÉ PERMISOS EN TU DISPOSITIVO ACCEDE?
Dependiendo de tu sistema operativo Android o IOS y de la versión de la aplicación instalada en tu dispositivo, la aplicación de puede acceder a los siguientes permisos del dispositivo. Mantén siempre actualizada tu aplicación de para que puedas utilizar las funcionalidades más recientes y seguras.

    Receive text messages (Recibir mensajes de texto) – Esto se utiliza para confirmar automáticamente la contraseña de un solo uso (OTP por sus siglas en inglés) enviada a tu dispositivo a través de SMS.
    Camera (Cámara) – te pedirá que subas fotos de tu identificación. también puede pedirte que subas copias de documentos para acreditar tus ingresos.
    Albums (Álbumes) -puede pedirte que nos permitas comprobar los datos de información de almacenamiento de tu álbum (número de fotos, memoria de fotos, fecha de creación de las fotos).nunca accederá a tus imágenes fotográficas y nunca compartirá estos contenidos.
    Read, modify, or delete the contents of your SD card (Leer, modificar o borrar el contenido de tu tarjeta SD) – también puede solicitarte que subas fotos de documentos para acreditar tus ingresos.
Read phone status and identity (Leer el estado del teléfono y la identidad) – Cuando te registras para obtener una cuenta de, recupera tu número de teléfono de tu dispositivo automáticamente. Esto asegura que el número de teléfono celular está activo y es correcto, y que está vinculado al dispositivo que estás usando para abrir la cuenta.
Location (Ubicación) – Esto ayuda a nuestros modelos de fraude a verificar tu identidad. también utiliza esta información en sus modelos de crédito y aseguramiento para determinar si eres elegible para los servicios. También utilizamos los datos de ubicación para fines de investigación.
    Run at startup (Ejecutar al inicio) – Esto permite que la aplicación de envíe notificaciones a tu dispositivo cuando lo reinicies.
    View and change network connectivity (Ver y cambiar la conectividad de la red) – Esto se utiliza para notificar a la aplicación de cuando la conectividad de la red cambie para que podamos determinar si estás conectado a Internet o no.
    View Wi-Fi connections (Ver conexiones Wi-Fi) – utiliza la dirección IP y el tipo de red de tu dispositivo para detectar y prevenir fraudes.
    Receive data from the internet (Recibir datos de internet) – necesita este permiso para enviar solicitudes a través de la aplicación de y para permitir que dicha aplicación acceda a internet.
    Prevent phone from sleeping (Evitar que el teléfono entre en modo inactivo) – Este permiso es requerido por algunas de las características y servicios dentro de la aplicación de, como los mensajes enviados por la propia aplicación.

¿CON QUIÉN COMPARTIMOS SUS DATOS?

Los datos personales se transferirán a nivel nacional e internacional, a las personas o entidades ubicadas en los siguientes tipos, categorías o sectores, con las siguientes finalidades:

A. A autoridades nacionales y extranjeras competentes, con la finalidad de dar cumplimiento a alguna ley, reglamento o disposición legal aplicable, así como con la finalidad de prevenir un daño.

B. A terceros que adquieran la cartera crediticia de o que otorguen financiamiento a con la finalidad de que pueda obtener financiamiento o recursos.

C. A terceros interesados en otorgar financiamientos o adquirir las acciones o activos de o de sus accionistas o filiales en México o alrededor del mundo o en fusionarse con.

D. Con nuestros socios comerciales, para fines de prospección comercial y servicios relacionados ofrecidos por y para darte acceso a los servicios de terceros dentro de la aplicación de.

Asimismo, y de acuerdo con las leyes vigentes, deberá, con el objeto de cumplir con las Finalidades Primarias descritas en el presente, transferir de tiempo en tiempo, información de crédito del Titular a las Sociedades de Información Crediticia o Burós de Crédito, y en este sentido, al aceptar el presente Aviso de Privacidad (en lo sucesivo, este "Aviso") y al consentir a la celebración del Contrato de Usuario, usted confirma que podemos transmitir su información a dichas Sociedades de Información Crediticia o Burós de Crédito, incluyendo información sobre el incumplimiento a los contratos celebrados entre el Titular y, entre otros el Contrato del Usuario.

También compartimos tus datos personales con terceros prestadores de servicios relacionados con los desembolsos, la transferencia de fondos, servicios de pagos, proveedores de seguridad de información, aseguradoras, despachos de cobranza, asesores contables y legales, proveedores de software para análisis de datos, transferencias y servicios de almacenamiento y otros prestadores de servicios que procesan tus datos personales por cuenta de.

CLÁUSULA PARA CONSENTIR LA TRANSFERENCIA

Por favor considerar que no será necesario tener tu consentimiento para llevar a cabo: (i) la transferencia indicada en los incisos A y B de la sección anterior, por encontrarse en el supuesto establecido en la fracción IV del artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y (ii) aquellas transferencias que se encuentren dentro de los supuestos establecidos en el Artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Tampoco requerimos tu consentimiento para compartir tus Datos Personales con encargados como los prestadores de servicios mencionados anteriormente.

Para llevar a cabo las transferencias en los incisos C, D y E de la sección anterior, sí requerimos su consentimiento. Usted otorga su consentimiento para estas transferencias al aceptar este Aviso de Privacidad.
TUS DERECHOS ARCO

El formato de ejercicio de Derechos ARCO se deberá llenar, firmar y presentarse acompañado de la siguiente documentación, a fin de que pueda llevarse a cabo la autenticación de usted:
    Tu identificación oficial vigente (Credencial del Instituto Nacional Electoral, Pasaporte, Cartilla del servicio Militar Nacional o Cédula Profesional).
    En los casos en que el ejercicio de los Derechos ARCO se realice a través de tu representante legal, deberá acompañarse la identificación oficial del representante, así como el poder correspondiente protocolizado que acredite la representación legal conferida por el Titular.
Cuando se quiera ejercer el derecho de rectificación, deberá exhibirse la documentación que acredite el cambio solicitado de acuerdo a los datos personales a rectificar.

PUEDES NEGARTE O REVOCAR EL CONSENTIMIENTO PARA TRATAR TUS DATOS

En caso de que desees revocar tu consentimiento o negarte al tratamiento de tus datos personales para las Finalidades Secundarias, con posterioridad a la firma y aceptación del presente Aviso, deberás enviar un correo electrónico a la dirección indicada en la sección anterior al Departamento de Protección de Datos Personales/Equipo de Atención al Cliente, siguiendo el procedimiento para la Negativa al Tratamiento y Revocación del Consentimiento que se describe en este Aviso.

De acuerdo con lo anterior, el Departamento de Protección de Datos Personales/Equipo de Atención al Cliente es el encargado de atender todas las solicitudes de ejercicio de los derechos ARCO (acceso, rectificación, cancelación u oposición) mismo que puedes contactar en caso de cualquier duda o solicitud con respecto a tus datos personales a través del correo electrónico y teléfono antes indicados.
¿QUÉ MEDIOS TIENES PARA LIMITAR EL USO Y DIVULGACIÓN DE DATOS PERSONALES?

a nosotros ha creado un listado de exclusión para personas que no deseen recibir publicidad de. Si recibes publicidad de a nosotros y no deseas seguir recibiéndola, podrás enviar un correo electrónico con tu nombre completo, solicitando tu eliminación de la lista de distribución con base en la cual se lleva a cabo el envío de la información o publicidad. En el mismo correo electrónico podrás solicitar más datos acerca de este listado.

Puedes también inscribirte en el Registro Público de Usuarios de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros. Para mayor información, visite https://www.gob.mx/tramites/ficha/registro-publico-de-usuarios-reus-para-personas-fisicas/CONDUSEF2536.
CAMBIOS AL AVISO DE PRIVACIDAD

El presente Aviso se encuentra publicado para su consulta, en la página de Internet de. De igual forma, las modificaciones que en cualquier momento se hagan al presente Aviso, serán publicadas en dicha dirección.

Al hacer clic en el botón "ACEPTAR" al calce, el Titular otorga su consentimiento expreso en términos del presente Aviso y por ende, consiente que sus Datos Personales sean tratados por conforme a lo previsto en el presente Aviso, destinados para las finalidades indicadas en el presente y transferidos a los terceros establecidos aquí indicados.
DECISIONES AUTOMATIZADAS

Tomamos algunas decisiones en relación con nuestros productos y servicios en forma automatizada sin intervención de una persona física en la valoración.

I. Decisiones de otorgar préstamos

Cuando solicitas un préstamo, usaremos un proceso automatizado para decidir si prestamos dinero y/o para toma de decisiones respecto a tu elegibilidad para nuestros productos o servicios.

Nuestros procesos de revisión y otorgamiento de crédito utilizan inteligencia artificial para procesar tus datos personales y valorar tu capacidad crediticia. El tratamiento de tus datos personales es automatizado sin intervención o valoración humana. El uso de dichos procesos automatizados para verificar tu capacidad crediticia significa que podemos automáticamente decidir que no eres elegible para un préstamo o para un plazo o condiciones particulares. Nuestros modelos de suscripción y otorgamiento de crédito son probados regularmente para asegurarnos que son justos, exactos y sin prejuicios.

II. Detección de fraudes

a nosotros también utiliza procesos automatizados para detectar, combatir y prevenir fraudes. Nuestros modelos de detección de fraudes automáticamente deciden si una persona puede significar riesgo de fraude o lavado de dinero. Si nuestros modelos de detección de fraude al procesar una transacción o aprobar un crédito detectan riesgo, es posible que el acceso a la persona se rehuse.

III. Tus derechos

Si solicitas un crédito y eres rechazado basado en una decisión automatizada, tienes derecho a acceder a tu información y rectificarla contactándonos como se establece en la sección Derechos ARCO.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
img {
  display: block;
}
.imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1,
.img2 {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2 {
  width: 100%;
  margin: 0;
}
.img2{
  padding: 0 220px;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3f4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}
.textBox {
  /* margin: 70px 0 150px; */
  margin: 30px 0 150px;
  width: 90%;
  font-size: 18px;
  color: #333333;
}
.textCard {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 32px;
  /* box-shadow: 0 2px 20.8px -5px rgba(0, 0, 0, 0.25); */
  margin: 10px 0 200px;
}

.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0baf60;
  margin: 0 500px;
}
.img1{
  margin-top: 20px;
}
</style>